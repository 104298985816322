import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { backendApiUrl } from 'utils/API'
import { prepareHeaders } from '../helpers/prepareHeaders'
import { createQueryFn } from '../helpers/createQueryFn'
import {
    PatientEditableData,
    PatientDetailsResponse,
    LabsAndImagingResponce,
} from './types'
import {
    MOCK_PATIENT_DETAILES,
    MOCK_PATIENT_VIEW,
} from 'utils/__Mocks__/PatientViewMock'
import { DEFAULT_PATIENT_DATA } from '../constants/patientData'
import { LABS_AND_IMAGING_MOCK } from 'utils/__Mocks__/LabsAndImagingMock'
import { generateLabsAndImagesUrl } from './apiUtils'

export const patientViewApi = createApi({
    reducerPath: 'patientViewApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${backendApiUrl}`,
        prepareHeaders,
    }),

    tagTypes: ['PatientView', 'PatientDetails', 'LabsAndImages'],

    endpoints: (builder) => ({
        getPatientView: builder.query<PatientDetailsResponse, string>({
            providesTags: (result, error, patientId) => [
                { type: 'PatientView', id: `${patientId}` },
            ],
            //@ts-ignore
            queryFn: createQueryFn(
                (patientId: string) => {
                    return `${backendApiUrl}/api/PatientView/patients/${patientId}/patientview`
                },
                () => MOCK_PATIENT_VIEW
            ),
        }),

        gerPatientDetails: builder.query<PatientEditableData, string>({
            providesTags: (result, error, patientId) => [
                { type: 'PatientDetails', id: `${patientId}` },
            ],
            //@ts-ignore
            queryFn: createQueryFn(
                (patientId: string) => {
                    return `${backendApiUrl}/${patientId}/patient-details`
                },
                () => MOCK_PATIENT_DETAILES
            ),
            // queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
            //     const result = await baseQuery(
            //         `${backendApiUrl}/${arg}/patient-details`
            //     )

            //     if (result.error) {
            //         // Handle error (e.g., 404, server issues, etc.)
            //         return { error: result.error }
            //     }

            //     if (result.meta?.response?.status === 204) {
            //         // Return default patient data for 204 response
            //         return { data: DEFAULT_PATIENT_DATA }
            //     }

            //     // Otherwise, return the actual result
            //     return { data: result.data as PatientEditableData }
            // },
        }),
        updatePatientDetails: builder.mutation<void, PatientEditableData>({
            query: ({ ...data }) => ({
                url: `/api/PatientView/${data.patientId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: (result, error) =>
                error ? [] : ['PatientDetails'],
        }),
        getLabsAndImages: builder.query<LabsAndImagingResponce, string>({
            providesTags: (result, error, patientId) => [
                { type: 'LabsAndImages', id: `${patientId}_LabsAndImages` },
            ],
            //@ts-ignore
            queryFn: createQueryFn(
                (patientId: string) =>
                    generateLabsAndImagesUrl(backendApiUrl, patientId),
                () => LABS_AND_IMAGING_MOCK
            ),
        }),
    }),
})

export const {
    useGetPatientViewQuery,
    useGerPatientDetailsQuery,
    useUpdatePatientDetailsMutation,
    useGetLabsAndImagesQuery,
} = patientViewApi
