import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import EN_TRANSLATION from './assets/locales/en/translation.json'
import FR_TRANSLATION from './assets/locales/fr/translation.json'
import EN_CORPORATE from './assets/locales/en/corporate.json'
import FR_CORPORATE from './assets/locales/fr/corporate.json'
import EN_WARD from './assets/locales/en/wardView.json'
import FR_WARD from './assets/locales/fr/wardView.json'
import EN_ED_LIVE from './assets/locales/en/edLive.json'
import FR_ED_LIVE from './assets/locales/fr/edLive.json'
import dayjs from 'dayjs'
require('dayjs/locale/fr')

const resources = {
    en: {
        translation: EN_TRANSLATION,
        corporate: EN_CORPORATE,
        wardView: EN_WARD,
        edLive: EN_ED_LIVE,
    },
    fr: {
        translation: FR_TRANSLATION,
        corporate: FR_CORPORATE,
        wardView: FR_WARD,
        edLive: FR_ED_LIVE,
    },
}

const userLang = navigator.language.substr(0, 2)
let lang = 'en'

if (userLang === 'fr') {
    dayjs.locale('fr')
    lang = 'fr'
}

i18n.use(initReactI18next).init({
    resources,
    lng: lang,
    fallbackLng: 'en',
    ns: ['translation', 'corporate'],
    defaultNS: 'translation',
    debug: false,
    interpolation: {
        escapeValue: false,
    },
})

export default i18n
