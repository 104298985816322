import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Patient } from '../wardView/types'
import { ActiveFilter, ManagementState } from './types'
import { updateFilters } from './helper'
import { LabAndImagingItem } from '../PatientView/types'

const initialState: ManagementState = {
    resizeWH: {
        width: 400,
        height: 200,
    },
    selectedWardId: null,
    doctorNamesList: [],
    selectedDoctor: null,
    wardFilters: [],
    filteredWards: [],
    labsImagingFilters: [],
    selectedPatientId: null,
    filteredLabsRecords: [],
}

const managemenSlice = createSlice({
    name: 'management',
    initialState,
    reducers: {
        setResizeWH(state, action) {
            state.resizeWH = action.payload
        },
        setSelectedWardId: (state, action: PayloadAction<number | null>) => {
            state.selectedWardId = action.payload
        },
        clearSelectedWardId: (state) => {
            state.selectedWardId = null
        },
        setDoctorNamesList: (state, action: PayloadAction<string[] | []>) => {
            state.doctorNamesList = action.payload
        },
        setSelectedDoctor: (state, action: PayloadAction<string | null>) => {
            state.selectedDoctor = action.payload
        },
        setWardFilters: (state, action: PayloadAction<ActiveFilter[]>) => {
            state.wardFilters = updateFilters(state.wardFilters, action.payload)
        },
        clearWardFilters: (state) => {
            state.wardFilters = []
        },
        setLabsImagingFiltersFilters: (
            state,
            action: PayloadAction<ActiveFilter[]>
        ) => {
            state.labsImagingFilters = updateFilters(
                state.labsImagingFilters,
                action.payload
            )
        },
        clearLabsImagingFilters: (state) => {
            state.labsImagingFilters = []
        },
        setFilteredWards: (state, action: PayloadAction<Patient[]>) => {
            state.filteredWards = action.payload
        },
        clearFilteredWards: (state, action: PayloadAction<Patient[]>) => {
            state.filteredWards = []
        },
        setSelectedPatientId: (state, action: PayloadAction<string | null>) => {
            state.selectedPatientId = action.payload
        },
        clearSelectedPatientId: (state) => {
            state.selectedPatientId = null
        },
        setFilteredLabsRecords: (
            state,
            action: PayloadAction<LabAndImagingItem[]>
        ) => {
            state.filteredLabsRecords = action.payload
        },
        clearFilteredLabsRecords: (state) => {
            state.filteredLabsRecords = []
        },
    },
    extraReducers: (builder) => {},
})

export const {
    setResizeWH,
    setSelectedWardId,
    clearSelectedWardId,
    setDoctorNamesList,
    setSelectedDoctor,
    setWardFilters,
    clearWardFilters,
    setLabsImagingFiltersFilters,
    clearLabsImagingFilters,
    setFilteredWards,
    clearFilteredWards,
    setSelectedPatientId,
    clearSelectedPatientId,
    setFilteredLabsRecords,
    clearFilteredLabsRecords,
} = managemenSlice.actions

export default managemenSlice.reducer
