import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    clearLabsImagingFilters,
    clearSelectedPatientId,
    clearSelectedWardId,
    setSelectedPatientId,
    setSelectedWardId,
} from 'redux/rtk/management/managemenSlice'
import {
    clearAllEditableData,
    setDefaultPatientViewTab,
} from 'redux/rtk/PatientView/PatientViewSlice'
import { DefaultPatientViewTab } from 'redux/rtk/PatientView/types'

type UseModalHandlersProps = {
    modalOpen: boolean
    handleOpenModal: (
        patientId: string,
        wardId: number,
        activeTab?: DefaultPatientViewTab
    ) => void
    handleCloseModal: () => void
}

export const usePatientViewModalHandlers = (): UseModalHandlersProps => {
    const [modalOpen, setModalOpen] = useState(false)
    const dispatch = useDispatch()
    const defaultTab: DefaultPatientViewTab = 'first'

    const handleOpenModal = useCallback(
        (
            patientId: string,
            wardId: number,
            activeTab?: DefaultPatientViewTab
        ) => {
            dispatch(setSelectedPatientId(patientId))
            dispatch(setSelectedWardId(wardId))
            dispatch(setDefaultPatientViewTab(activeTab ?? defaultTab))
            setModalOpen(true)
        },
        [dispatch]
    )

    const handleCloseModal = useCallback(() => {
        setModalOpen(false)
        dispatch(clearSelectedPatientId())
        dispatch(clearSelectedWardId())
        dispatch(setDefaultPatientViewTab(defaultTab))
        dispatch(clearLabsImagingFilters())
        dispatch(clearAllEditableData())
    }, [dispatch])

    return { modalOpen, handleOpenModal, handleCloseModal }
}
