import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    DefaultPatientViewTab,
    PatientDetails,
    PatientEditableData,
} from './types'
import {
    DEFAULT_PATIENT_DATA,
    DEFAULT_PATIENT_DETAILES,
} from '../constants/patientData'

type PatientViewState = {
    patientDetails: PatientDetails
    patientEditableData: PatientEditableData
    defaultPatientViewTab: DefaultPatientViewTab
}

const initialState: PatientViewState = {
    patientDetails: DEFAULT_PATIENT_DETAILES,
    patientEditableData: DEFAULT_PATIENT_DATA,
    defaultPatientViewTab: 'first',
}

const patientSlice = createSlice({
    name: 'patientView',
    initialState,
    reducers: {
        setPatientDetails: (state, action) => {
            state.patientDetails = action.payload
        },
        clearPatientDetails: (state) => {
            state.patientDetails = DEFAULT_PATIENT_DETAILES
        },
        setEditableData: (
            state,
            action: PayloadAction<PatientEditableData>
        ) => {
            state.patientEditableData = action.payload
        },
        updateEditableField: <K extends keyof PatientEditableData>(
            state: PatientViewState,
            action: PayloadAction<{ field: K; value: PatientEditableData[K] }>
        ) => {
            state.patientEditableData[action.payload.field] =
                action.payload.value
        },
        clearAllEditableData: (state) => {
            state.patientEditableData = DEFAULT_PATIENT_DATA
        },
        setDefaultPatientViewTab: (
            state,
            action: PayloadAction<DefaultPatientViewTab>
        ) => {
            state.defaultPatientViewTab = action.payload
        },
    },
})

export const {
    setPatientDetails,
    clearPatientDetails,
    setEditableData,
    updateEditableField,
    clearAllEditableData,
    setDefaultPatientViewTab,
} = patientSlice.actions
export default patientSlice.reducer
