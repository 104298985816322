import { useDebouncedCallback } from '@mantine/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { selectSelectedPatientId } from 'redux/rtk/management/selectors'
import { useUpdatePatientDetailsMutation } from 'redux/rtk/PatientView/PatientViewApi'
import { updateEditableField } from 'redux/rtk/PatientView/PatientViewSlice'
import { selectEditableData } from 'redux/rtk/PatientView/selectors'

export const useEditablePatientViewHandler = () => {
    const dispatch = useDispatch()
    const selectedPatientId = useSelector(selectSelectedPatientId)
    const editableData = useSelector(selectEditableData)
    const [updatePatientDetails, { isLoading }] =
        useUpdatePatientDetailsMutation()

    // Debounced function for API calls
    // Debounced function for API calls
    const debouncedUpdate = useDebouncedCallback(
        (field: string, value: string | boolean | null | Date) => {
            if (!selectedPatientId) return

            updatePatientDetails({
                ...editableData,
                [field]: value,
                patientId: selectedPatientId, // Ensure the correct patientId
            })
        },
        1000
    )

    const handleFieldChange = (
        field: keyof typeof editableData,
        value: string | boolean | null | Date
    ) => {
        dispatch(updateEditableField({ field, value }))
        // Trigger backend update with debounce
        debouncedUpdate(field, value)
    }

    return {
        editableData,
        handleFieldChange,
    }
}
