import CustomButton from 'components/common/CustomButton/CustomButton'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setPredictiveMode } from 'redux/rtk/filter/filterSlice'
import { selectPredictiveMode } from 'redux/rtk/filter/selectors'

const PredictionButton = (): ReactElement => {
    const { t } = useTranslation('corporate')
    const dispatch = useDispatch()
    const predictiveMode = useSelector(selectPredictiveMode)
    const handlePredictiveMode = () => {
        dispatch(setPredictiveMode(!predictiveMode))
    }
    return (
        <CustomButton
            variant="outline"
            customVariant={predictiveMode ? 'primary' : 'secondary'}
            radius="xl"
            size="lg"
            onClick={handlePredictiveMode}
        >
            {t('Predictive mode')}
        </CustomButton>
    )
}

export default PredictionButton
