import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useLogPageVisitMutation } from 'redux/rtk/corporateView/corporateViewApi'
import { selectUserDetails } from 'redux/rtk/user/selectors'

export const usePageVisitLogger = () => {
    const location = useLocation()
    const userDetails = useSelector(selectUserDetails)
    const [logPageVisit] = useLogPageVisitMutation()
    const lastPathname = useRef<string | null>(null)

    useEffect(() => {
        const logVisit = async (pageUrl: string, userName: string) => {
            try {
                await logPageVisit({
                    visitedPage: pageUrl,
                    userName: userName,
                }).unwrap()
                console.log(`Page visit logged: ${pageUrl}`)
            } catch (error) {
                console.error('Error logging page visit:', error)
            }
        }

        // Only log if the location has changed
        if (
            location.pathname !== lastPathname.current &&
            userDetails.userName
        ) {
            logVisit(location.pathname, userDetails.userName)
            lastPathname.current = location.pathname
        }
    }, [location.pathname, userDetails.userName, logPageVisit])
}
