export const LABS_AND_IMAGING_MOCK = {
    totalCount: 43607,
    page: 1,
    pageSize: 1000,
    data: [
        {
            unique_id: '048abb2c99941bd8b770e8cb9e3bcff9',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'LABS',
            request_status: 'Done',
            request_datetime: '2025-01-06T00:00:00',
            request_name: 'W5063200M:VREPC VRE (Nucleic Acid Detection)',
            result_datetime: '2025-01-07T14:08:00',
            result_description: 'VRE (Nucleic Acid Detection)',
            result_type: 'TOB:VREPC',
            result_value: '[This test is for investigational use only.]',
            result_unit: '',
            result_range_1: '',
            result_range_2: '',
            result_cursor: '0',
            result_flags: '',
            last_modified_date: '2025-01-08T11:32:16.466996',
            IsRead: false,
        },
        {
            unique_id: '0a6fdb09545114d05d34a3311490ae1c',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'LABS',
            request_status: 'Done',
            request_datetime: '2025-01-06T00:00:00',
            request_name:
                'W5063201M:CARS1 Carbapenem-Resistance Screen (CPE/MDRO/XDRO)',
            result_datetime: '2025-01-07T14:29:00',
            result_description: 'Carbapenem-Resistance Screen (CPE/MDRO/XDRO)',
            result_type: 'TOB:CARS1',
            result_value:
                '**Please Note: As of (2017-07-17) Carbap-R Enterobacteriaceae',
            result_unit: '',
            result_range_1: '',
            result_range_2: '',
            result_cursor: '0',
            result_flags: '',
            last_modified_date: '2025-01-08T11:32:16.466996',
            IsRead: false,
        },
        {
            unique_id: '18c71102054715588bccd368a42f2f98',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'LABS',
            request_status: 'Pending',
            request_datetime: '2025-01-06T00:00:00',
            request_name: 'W5061139:MDIFF Differential (Manual)',
            result_datetime: '2025-01-06T09:35:00',
            result_description: 'Smear cell count of 100',
            result_type: 'LIMIT',
            result_value: '100',
            result_unit: '',
            result_range_1: '',
            result_range_2: '',
            result_cursor: '0',
            result_flags: '',
            last_modified_date: '2025-01-08T11:32:16.466996',
            IsRead: true,
        },
        {
            unique_id: 'a5b4f1b5bf3e7c6f1692ad2fc127b5a8',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'IMAGES',
            request_status: 'Pending',
            request_datetime: '2024-09-30T00:00:00',
            request_name: null,
            result_datetime: null,
            result_description: '1ST PASS OR INTRACARDIAC SHUNT',
            result_type: null,
            result_value: null,
            result_unit: null,
            result_range_1: null,
            result_range_2: null,
            result_cursor: null,
            result_flags: null,
            last_modified_date: '2025-01-16T05:21:55.411913',
            IsRead: true,
        },
        {
            unique_id: '2a51055772484bb2c585e07a60b19361',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'LABS',
            request_status: 'Pending',
            request_datetime: '2025-01-06T00:00:00',
            request_name: 'W5061139:MDIFF Differential (Manual)',
            result_datetime: '2025-01-06T09:35:00',
            result_description: 'SMEAR1',
            result_type: 'SMEAR',
            result_value: 'En cours',
            result_unit: '',
            result_range_1: '',
            result_range_2: '',
            result_cursor: '0',
            result_flags: '',
            last_modified_date: '2025-01-08T11:32:16.466996',
            IsRead: false,
        },
        {
            unique_id: '3bb8d757e704acba66fb1dcbebcd54e0',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'LABS',
            request_status: 'Done',
            request_datetime: '2025-01-06T00:00:00',
            request_name:
                'W5063201M:CARS1 Carbapenem-Resistance Screen (CPE/MDRO/XDRO)',
            result_datetime: '2025-01-07T14:29:00',
            result_description: 'Carbapenem-Resistance Screen (CPE/MDRO/XDRO)',
            result_type: 'TOB:CARS1',
            result_value:
                'No Carbapenem Resistant Enterobacteriaceae or Meropenem',
            result_unit: '',
            result_range_1: '',
            result_range_2: '',
            result_cursor: '0',
            result_flags: '',
            last_modified_date: '2025-01-08T11:32:16.466996',
            IsRead: false,
        },
        {
            unique_id: '3fc4f0e8c74a49549f05a8abc1f6c917',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'LABS',
            request_status: 'Pending',
            request_datetime: '2025-01-06T00:00:00',
            request_name: 'W5061139:MDIFF:SDIFF ',
            result_datetime: '2025-01-06T09:35:00',
            result_description: 'RBC Agglutination',
            result_type: 'RAGG',
            result_value: '.',
            result_unit: '',
            result_range_1: '',
            result_range_2: '',
            result_cursor: '0',
            result_flags: '',
            last_modified_date: '2025-01-08T11:32:16.466996',
            IsRead: true,
        },
        {
            unique_id: '5416e3b53c296d530881c208c60716af',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'LABS',
            request_status: 'Pending',
            request_datetime: '2025-01-06T00:00:00',
            request_name: 'W5061139:MDIFF:SDIFF ',
            result_datetime: '2025-01-06T09:35:00',
            result_description: 'Platelet Estimation',
            result_type: 'PLTES',
            result_value: '.',
            result_unit: '',
            result_range_1: '',
            result_range_2: '',
            result_cursor: '0',
            result_flags: '',
            last_modified_date: '2025-01-08T11:32:16.466996',
            IsRead: false,
        },
        {
            unique_id: '55ab9bde51c7d5e27dfcad06c0c52c82',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'LABS',
            request_status: 'Pending',
            request_datetime: '2025-01-06T00:00:00',
            request_name: 'W5061139:MDIFF:SDIFF ',
            result_datetime: '2025-01-06T09:35:00',
            result_description: 'Giant Platelets',
            result_type: 'GIPLT',
            result_value: '.',
            result_unit: '',
            result_range_1: '',
            result_range_2: '',
            result_cursor: '0',
            result_flags: '',
            last_modified_date: '2025-01-08T11:32:16.466996',
            IsRead: true,
        },
        {
            unique_id: '609246ff223160623d35a5497c7b44ca',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'LABS',
            request_status: 'Done',
            request_datetime: '2025-01-06T00:00:00',
            request_name:
                'W5063201M:CARS1 Carbapenem-Resistance Screen (CPE/MDRO/XDRO)',
            result_datetime: '2025-01-07T14:29:00',
            result_description: 'Carbapenem-Resistance Screen (CPE/MDRO/XDRO)',
            result_type: 'TOB:CARS1',
            result_value: 'merged into one test.**',
            result_unit: '',
            result_range_1: '',
            result_range_2: '',
            result_cursor: '0',
            result_flags: '',
            last_modified_date: '2025-01-08T11:32:16.466996',
            IsRead: false,
        },
        {
            unique_id: '6508ec660edfcf88488e24972f72b3e2',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'LABS',
            request_status: 'Done',
            request_datetime: '2025-01-06T00:00:00',
            request_name: 'W5063200M:VREPC VRE (Nucleic Acid Detection)',
            result_datetime: '2025-01-07T14:08:00',
            result_description: 'VRE (Nucleic Acid Detection)',
            result_type: 'TOB:VREPC',
            result_value: 'Negative for VRE (vanA/vanB).',
            result_unit: '',
            result_range_1: '',
            result_range_2: '',
            result_cursor: '0',
            result_flags: '',
            last_modified_date: '2025-01-08T11:32:16.466996',
            IsRead: false,
        },
        {
            unique_id: '6665dad7c1649dad7027f18491027be8',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'LABS',
            request_status: 'Done',
            request_datetime: '2025-01-06T00:00:00',
            request_name:
                'W5063201M:CARS1 Carbapenem-Resistance Screen (CPE/MDRO/XDRO)',
            result_datetime: '2025-01-07T14:29:00',
            result_description: 'Carbapenem-Resistance Screen (CPE/MDRO/XDRO)',
            result_type: 'TOB:CARS1',
            result_value:
                'Screen and Acinetobacter Resistance Screen have been',
            result_unit: '',
            result_range_1: '',
            result_range_2: '',
            result_cursor: '0',
            result_flags: '',
            last_modified_date: '2025-01-08T11:32:16.466996',
            IsRead: false,
        },
        {
            unique_id: '80a5e0c737bc5563f2d91c43c8904312',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'LABS',
            request_status: 'Done',
            request_datetime: '2025-01-06T00:00:00',
            request_name:
                'W5063201M:CARS1 Carbapenem-Resistance Screen (CPE/MDRO/XDRO)',
            result_datetime: '2025-01-07T14:29:00',
            result_description: 'Carbapenem-Resistance Screen (CPE/MDRO/XDRO)',
            result_type: 'TOB:CARS1',
            result_value: 'Resistant Acinetobacter isolated.',
            result_unit: '',
            result_range_1: '',
            result_range_2: '',
            result_cursor: '0',
            result_flags: '',
            last_modified_date: '2025-01-08T11:32:16.466996',
            IsRead: false,
        },
        {
            unique_id: '81b901a5792c20762d24a5851dfbf7b2',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'LABS',
            request_status: 'Pending',
            request_datetime: '2025-01-06T00:00:00',
            request_name: 'W5061139:MDIFF:SDIFF ',
            result_datetime: '2025-01-06T09:35:00',
            result_description: 'Large Platelets',
            result_type: 'LAPLT',
            result_value: '.',
            result_unit: '',
            result_range_1: '',
            result_range_2: '',
            result_cursor: '0',
            result_flags: '',
            last_modified_date: '2025-01-08T11:32:16.466996',
            IsRead: false,
        },
        {
            unique_id: '8354dd089cc3ec8bc32fba5ffa94664b',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'LABS',
            request_status: 'Pending',
            request_datetime: '2025-01-06T00:00:00',
            request_name: 'W5061139:MDIFF:SDIFF ',
            result_datetime: '2025-01-06T09:35:00',
            result_description: '    Reviewed by:',
            result_type: 'DIFFR',
            result_value: '.',
            result_unit: '',
            result_range_1: '',
            result_range_2: '',
            result_cursor: '0',
            result_flags: '',
            last_modified_date: '2025-01-08T11:32:16.466996',
            IsRead: false,
        },
        {
            unique_id: '995204ea0de439ad8ed5a13491dc0942',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'LABS',
            request_status: 'Done',
            request_datetime: '2025-01-06T00:00:00',
            request_name: 'W5063200M:VREPC VRE (Nucleic Acid Detection)',
            result_datetime: '2025-01-07T14:08:00',
            result_description: 'VRE (Nucleic Acid Detection)',
            result_type: 'TOB:VREPC',
            result_value: 'A culture will NOT be performed on this specimen.',
            result_unit: '',
            result_range_1: '',
            result_range_2: '',
            result_cursor: '0',
            result_flags: '',
            last_modified_date: '2025-01-08T11:32:16.466996',
            IsRead: false,
        },
        {
            unique_id: 'a0caaeb032c87206f5ef39398a747133',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'LABS',
            request_status: 'Pending',
            request_datetime: '2025-01-06T00:00:00',
            request_name: 'W5061139:MDIFF:SDIFF ',
            result_datetime: '2025-01-06T09:35:00',
            result_description: ' ',
            result_type: 'SCAN_',
            result_value: 'En cours',
            result_unit: '',
            result_range_1: '',
            result_range_2: '',
            result_cursor: '0',
            result_flags: '',
            last_modified_date: '2025-01-08T11:32:16.466996',
            IsRead: false,
        },
        {
            unique_id: 'a16285bd85eeba910369d84ae97ae79a',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'LABS',
            request_status: 'Pending',
            request_datetime: '2025-01-06T00:00:00',
            request_name: 'W5061139:MDIFF:SDIFF ',
            result_datetime: '2025-01-06T09:35:00',
            result_description: 'Manual Smear',
            result_type: 'DIFF_',
            result_value: 'to follow',
            result_unit: '',
            result_range_1: '',
            result_range_2: '',
            result_cursor: '0',
            result_flags: '',
            last_modified_date: '2025-01-08T11:32:16.466996',
            IsRead: false,
        },
        {
            unique_id: 'a41e0a741eb1f9727294ddd3a2f489f5',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'LABS',
            request_status: 'Pending',
            request_datetime: '2025-01-06T00:00:00',
            request_name: 'W5061139:MDIFF Differential (Manual)',
            result_datetime: '2025-01-06T09:35:00',
            result_description: 'Corrected WBC',
            result_type: 'CORWC',
            result_value: '.',
            result_unit: '',
            result_range_1: '',
            result_range_2: '',
            result_cursor: '0',
            result_flags: '',
            last_modified_date: '2025-01-08T11:32:16.466996',
            IsRead: false,
        },
        {
            unique_id: 'b7a89f366245bbf896282e889b09be8f',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'LABS',
            request_status: 'Pending',
            request_datetime: '2025-01-06T00:00:00',
            request_name: 'W5061139:MDIFF:SDIFF ',
            result_datetime: '2025-01-06T09:35:00',
            result_description: 'Platelet Clumps',
            result_type: 'PLTCL',
            result_value: '.',
            result_unit: '',
            result_range_1: '',
            result_range_2: '',
            result_cursor: '0',
            result_flags: '',
            last_modified_date: '2025-01-08T11:32:16.466996',
            IsRead: false,
        },
        {
            unique_id: 'd283c89fc2fc6699f684752d14c64989',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'LABS',
            request_status: 'Pending',
            request_datetime: '2025-01-06T00:00:00',
            request_name: 'W5061139:MDIFF:SDIFF ',
            result_datetime: '2025-01-06T09:35:00',
            result_description: 'Dummy test used for RBS rule',
            result_type: 'DCHK',
            result_value: 'CHECK',
            result_unit: '',
            result_range_1: '',
            result_range_2: '',
            result_cursor: '0',
            result_flags: '',
            last_modified_date: '2025-01-08T11:32:16.466996',
            IsRead: false,
        },
        {
            unique_id: 'de3bc0097ba1802be21ac8ca54e61faa',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'LABS',
            request_status: 'Pending',
            request_datetime: '2025-01-06T00:00:00',
            request_name: 'W5061139:MDIFF:SDIFF ',
            result_datetime: '2025-01-06T09:35:00',
            result_description: ' ',
            result_type: 'DIFFX',
            result_value: '-',
            result_unit: '',
            result_range_1: '',
            result_range_2: '',
            result_cursor: '0',
            result_flags: '',
            last_modified_date: '2025-01-08T11:32:16.466996',
            IsRead: false,
        },
        {
            unique_id: 'ded00873c94a62559b516f464c198161',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'LABS',
            request_status: 'Pending',
            request_datetime: '2025-01-06T00:00:00',
            request_name: 'W5061139:MDIFF:SDIFF ',
            result_datetime: '2025-01-06T09:35:00',
            result_description: '',
            result_type: 'MISC',
            result_value: '.',
            result_unit: '',
            result_range_1: '',
            result_range_2: '',
            result_cursor: '0',
            result_flags: '',
            last_modified_date: '2025-01-08T11:32:16.466996',
            IsRead: false,
        },
        {
            unique_id: '0b4d28ac5358d838a6b7a2567fcafe3d',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'IMAGES',
            request_status: 'Pending',
            request_datetime: '2024-12-02T00:00:00',
            request_name: null,
            result_datetime: null,
            result_description: 'GENERAL ANAESTHESIA',
            result_type: null,
            result_value: null,
            result_unit: null,
            result_range_1: null,
            result_range_2: null,
            result_cursor: null,
            result_flags: null,
            last_modified_date: '2025-01-16T05:27:02.342033',
            IsRead: false,
        },
        {
            unique_id: '6f124f87fad120f7604dd889c50c78a4',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'IMAGES',
            request_status: 'Pending',
            request_datetime: '2024-12-02T00:00:00',
            request_name: null,
            result_datetime: null,
            result_description: 'COMPRESSION( MANUAL) OF PUNCTURE SITE IN ROOM',
            result_type: null,
            result_value: null,
            result_unit: null,
            result_range_1: null,
            result_range_2: null,
            result_cursor: null,
            result_flags: null,
            last_modified_date: '2025-01-16T05:27:02.342033',
            IsRead: false,
        },
        {
            unique_id: '9dbc84255642cded64fbeae7ab42eaac',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'IMAGES',
            request_status: 'Pending',
            request_datetime: '2024-12-19T00:00:00',
            request_name: null,
            result_datetime: null,
            result_description: 'INTERROGATION SANS ETUDE DE SEUIL',
            result_type: null,
            result_value: null,
            result_unit: null,
            result_range_1: null,
            result_range_2: null,
            result_cursor: null,
            result_flags: null,
            last_modified_date: '2025-01-16T05:27:02.342033',
            IsRead: false,
        },
        {
            unique_id: 'e3fcfeff8d4a72c5e37d18f1bf3839bc',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'IMAGES',
            request_status: 'Pending',
            request_datetime: '2024-12-02T00:00:00',
            request_name: null,
            result_datetime: null,
            result_description: 'AORTIC VALVE IMPLANT OR REPAIR',
            result_type: null,
            result_value: null,
            result_unit: null,
            result_range_1: null,
            result_range_2: null,
            result_cursor: null,
            result_flags: null,
            last_modified_date: '2025-01-16T05:27:02.342033',
            IsRead: false,
        },
        {
            unique_id: '0b4d28ac5358d838a6b7a2567fcafe3d',
            patient_uuid: '4f4cbead-f3f6-485f-a5ef-fd07bdfaf677',
            request_category: 'IMAGES',
            request_status: 'Pending',
            request_datetime: '2024-12-02T00:00:00',
            request_name: null,
            result_datetime: null,
            result_description: 'GENERAL ANAESTHESIA',
            result_type: null,
            result_value: null,
            result_unit: null,
            result_range_1: null,
            result_range_2: null,
            result_cursor: null,
            result_flags: null,
            last_modified_date: '2025-01-16T05:27:02.342033',
            IsRead: false,
        },
    ],
}
