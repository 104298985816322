import { RootState } from '../index'
import { PatientDetails } from './types'

export const selectpatientDetails = (state: RootState) =>
    state.patientView.patientDetails

export const selectEditableData = (state: RootState) =>
    state.patientView.patientEditableData

export const selectDefaultPatientViewTab = (state: RootState) =>
    state.patientView.defaultPatientViewTab

export const selectPatientProfile = (patientDetails: PatientDetails) => ({
    mrn: patientDetails.mrn || '',
    locationUnitName: patientDetails.locationUnitName || '',
    locationRoomName: patientDetails.locationRoomName || '',
    locationBedName: patientDetails.locationBedName || '',
    visitFollowingDoctorName: patientDetails.visitFollowingDoctorName || '',
    visitDiagnosis: patientDetails.visitDiagnosis || '',
    communityIntervenantPivotName:
        patientDetails.communityIntervenantPivotName || '',
})

export const selectPatientViewGeneral = (patientDetails: PatientDetails) => ({
    firstName: patientDetails.firstName || '',
    lastName: patientDetails.lastName || '',
    patientGender: patientDetails.patientGender || '',
    patientAge: patientDetails.patientAge || '',
})
