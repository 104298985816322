import React from 'react'
import styles from './CustomButton.module.scss'
import { Button, ButtonProps } from '@mantine/core'
import { useSelector } from 'react-redux'
import { selectTheme } from '../../../redux/rtk/user/selectors'

interface CustomButtonProps extends ButtonProps {
    customVariant?: 'primary' | 'secondary'
    buttonClassName?: string
    customStyle?: React.CSSProperties
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    disabled?: boolean
    // Add more props as needed
}

const CustomButton: React.FC<CustomButtonProps> = ({
    children,
    buttonClassName,
    customVariant,
    customStyle,
    onClick,
    disabled,
    ...props
}) => {
    const className = `button_${customVariant}`

    return (
        <Button
            classNames={{
                root: buttonClassName
                    ? `${styles.button} ${buttonClassName}`
                    : `${styles.button} ${styles[className]}`,
            }}
            style={customStyle}
            disabled={disabled}
            onClick={onClick}
            {...props}
        >
            {children}
        </Button>
    )
}

export default CustomButton
