import { MRT_ColumnFiltersState } from 'mantine-react-table'
import { ActiveFilter } from './types'

export const updateFilters = (
    currentFilters: MRT_ColumnFiltersState,
    incomingFilters: ActiveFilter[]
): MRT_ColumnFiltersState => {
    const updatedFilters = [...currentFilters]

    incomingFilters?.forEach(({ id, value }) => {
        if (value === null || value === undefined) {
            // Remove filter with matching id
            const index = updatedFilters.findIndex((filter) => filter.id === id)
            if (index !== -1) {
                updatedFilters.splice(index, 1)
            }
        } else {
            // Update or add the filter
            const existingIndex = updatedFilters.findIndex(
                (filter) => filter.id === id
            )

            if (existingIndex !== -1) {
                // Merge new values with existing ones (if they are arrays)
                const existingValue = updatedFilters[existingIndex].value

                if (Array.isArray(existingValue)) {
                    updatedFilters[existingIndex].value = Array.isArray(value)
                        ? Array.from(new Set([...existingValue, ...value]))
                        : Array.from(new Set([...existingValue, value]))
                } else {
                    updatedFilters[existingIndex].value = Array.isArray(value)
                        ? Array.from(new Set([existingValue, ...value]))
                        : value
                }
            } else {
                // Add the new filter
                updatedFilters.push({
                    id,
                    value: Array.isArray(value) ? value : [value],
                })
            }
        }
    })

    return updatedFilters
}
