import React, { Fragment, useEffect, useMemo, useState } from 'react'
import styles from './UserMenu.module.scss'
import { Menu, Text } from '@mantine/core'
import { icons } from '../../../utils/iconsMap'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useKeycloak } from '@react-keycloak/web'
import TermsOfService from '../../TermsOfService/TermsOfService'
import UserGuide from '../../TermsOfService/UserGuide'
import UserRolesItems from './UserRolesItems/UserRolesItems'
import LanguageBlock from './LanguageBlock/LanguageBlock'
import ThemeBlock from './ThemeBlock/ThemeBlock'
import ServiceInfoBlock from './ServiceInfoBlock/ServiceInfoBlock'
import dayjs from 'dayjs'
import {
    selectActiveUserRole,
    selectTheme,
    selectUserDetails,
} from '../../../redux/rtk/user/selectors'
import { getUserDetails } from '../../../redux/rtk/user/asyncAction'
import { selectToken } from '../../../redux/rtk/auth/selectors'
import {
    useLazyGetShowTermsOfServiceQuery,
    useGetShowTermsOfServiceQuery,
    useLazyGetTermsOfServiceForPopupQuery,
} from '../../../redux/rtk/terms/termsConditionsSlice'
import {
    useGetSystemQuery,
    useLazyGetSystemQuery,
} from '../../../redux/rtk/visits/visitsApiSlice'
import { extractBeforeFirstSpecialSymbol } from '../../../utils/strings/extractBeforeFirstSpecialSymbol '
import { selectIsAcceptedTerms } from '../../../redux/rtk/termsAndOther/selectors'
import { useConditionalQuery } from '../../../hooks'
import { setAcceptTerms } from '../../../redux/rtk/termsAndOther/termsAndOtherSlice'
import { useAuth } from 'react-oidc-context'
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

const UserMenu = ({ type }: { type?: string }): JSX.Element => {
    const [isTermsModalOpen, setIsTermsModalOpen] = useState<boolean>(false)
    const [isUserGuideOpen, setIsUserGuideOpen] = useState<boolean>(false)

    const auth = useAuth()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const userDetails = useSelector(selectUserDetails)
    const theme = useSelector(selectTheme)
    const userToken = useSelector(selectToken)
    const activeUserRole = useSelector(selectActiveUserRole)
    const isAcceptedTerms = useSelector(selectIsAcceptedTerms)

    // const [getTermsOfServiceForPopup, { data: termsOfService }] =
    //     useLazyGetTermsOfServiceForPopupQuery()

    const { data: termsAndConditionsData } = useGetShowTermsOfServiceQuery(
        undefined,
        {
            skip: !userToken,
        }
    )

    // const [getSystem, { data }] = useLazyGetSystemQuery()

    // useEffect(() => {
    //     if (userToken) {
    //         // getTermsOfServiceForPopup()
    //         // dispatch(getUserDetails())
    //         getShowTermsOfService()
    //     }
    // }, [dispatch])
    // const fetchContitions = [!!userToken, !!activeUserRole, isAcceptedTerms]
    const fetchContitions = useMemo(
        () => [!!userToken, !!activeUserRole, isAcceptedTerms],
        [userToken, activeUserRole, isAcceptedTerms, termsAndConditionsData]
    )
    // const { data, error, isFetching, isSuccess } = useConditionalQuery(
    //     useGetSystemQuery,
    //     undefined,
    //     fetchContitions
    // )

    useEffect(() => {
        // if (!userToken) return
        if (termsAndConditionsData) {
            dispatch(
                setAcceptTerms(!termsAndConditionsData?.showTermsAndConditions)
            )
        }
        if (termsAndConditionsData?.showTermsAndConditions) {
            setIsTermsModalOpen(true)
        }
    }, [termsAndConditionsData, userToken])

    useEffect(() => {
        if (!userToken) return
        if (
            termsAndConditionsData &&
            !termsAndConditionsData?.showTermsAndConditions
        ) {
            dispatch(getUserDetails())
            // getTermsOfServiceForPopup()
            // getSystem({})
        }
    }, [termsAndConditionsData, isAcceptedTerms, dispatch])

    const handleLogout = async () => {
        try {
            // Check if there is a user object and it has the id_token
            if (auth.user && auth.user.id_token) {
                const idTokenHint = auth.user.id_token

                // Call signoutRedirect with id_token_hint
                await auth.signoutRedirect({ id_token_hint: idTokenHint })
            } else {
                // Fallback in case user is not authenticated
                console.error('User not found or no ID token available')
                await auth.signoutRedirect()
            }

            // Optionally remove user data after the signoutRedirect
            auth.removeUser()
        } catch (error) {
            console.error('Error during logout:', error)
        }
    }

    const parcedUserName = useMemo(
        () => extractBeforeFirstSpecialSymbol(userDetails?.userName),
        [userDetails]
    )

    return (
        <Fragment>
            <div className={styles.user_menu}>
                <div
                    className={`${styles.user_menu_user} ${
                        type
                            ? styles.user_menu_user_jgh
                            : styles.user_menu_user_normal
                    }`}
                >
                    <Menu
                        transitionProps={{
                            transition: 'scale-x',
                            duration: 150,
                        }}
                        classNames={{
                            dropdown: `${styles.user_menu_body} ${
                                styles[theme + '-theme']
                            }`,
                            item: `${styles.user_menu__item}`,
                        }}
                    >
                        <Menu.Target>
                            <div className={styles.user_menu_control}>
                                <img
                                    src={icons.avatar}
                                    className={styles.user_menu__img}
                                    alt={t('Avatar')}
                                />
                                <button className={styles.menu_label}>
                                    {parcedUserName || 'Guest'}
                                </button>
                            </div>
                        </Menu.Target>
                        <Menu.Dropdown>
                            {userDetails?.roles?.length > 0 && (
                                <Menu.Item
                                    key="User Roles Items"
                                    className={styles.menu_label__item}
                                >
                                    <UserRolesItems />
                                </Menu.Item>
                            )}
                            <LanguageBlock />
                            {/* <ThemeBlock /> */}
                            <Menu.Item
                                key="Terms of service"
                                className={styles.menu_label__item}
                                onClick={() => setIsTermsModalOpen(true)}
                            >
                                <Text
                                    size="xl"
                                    fw={600}
                                    variant="gradient"
                                    className={styles.menu_label__terms}
                                >
                                    {t('Terms of service')}
                                </Text>
                            </Menu.Item>
                            {/* <Menu.Item
                                key="User guide"
                                className={styles.menu_label__item}
                                onClick={() => setIsUserGuideOpen(true)}
                            >
                                <Text
                                    size="xl"
                                    fw={600}
                                    variant="gradient"
                                    className={styles.menu_label__terms}
                                >
                                    {t('User guide')}
                                </Text>
                            </Menu.Item> */}
                            <Menu.Item
                                className={styles.menu_label__item}
                                onClick={handleLogout}
                            >
                                <Text
                                    size="xl"
                                    fw={600}
                                    variant="gradient"
                                    className={styles.menu_label__logout}
                                >
                                    {t('Log out')}
                                </Text>
                            </Menu.Item>
                            {/* {data ? <ServiceInfoBlock data={data} /> : null} */}
                        </Menu.Dropdown>
                    </Menu>
                </div>
            </div>
            {isTermsModalOpen && (
                <TermsOfService
                    setIsOpen={setIsTermsModalOpen}
                    isOpen={isTermsModalOpen}
                />
            )}
            {/* {isUserGuideOpen && (
                <UserGuide
                    isOpen={isUserGuideOpen}
                    setIsOpen={setIsUserGuideOpen}
                />
            )} */}
        </Fragment>
    )
}

export default UserMenu
