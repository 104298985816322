import { useState, useCallback } from 'react'

type SelectedButton = {
    id: string
    value: string[]
}

const isSelectedButtonsEmpty = (buttons: SelectedButton[]): boolean => {
    return buttons.every((button) => button.value.length === 0)
}

const useSelectedButtonsLogic = (initialState: SelectedButton[] = []) => {
    const [selectedButtons, setSelectedButtons] =
        useState<SelectedButton[]>(initialState)

    const handleButtonClick = useCallback(
        (filterId: string, names: string | string[]) => {
            setSelectedButtons((prev) => {
                const namesArray = Array.isArray(names) ? names : [names]

                if (namesArray.length > 1) {
                    const updatedFilter = { id: filterId, value: namesArray }

                    return [
                        ...prev.filter((button) => button.id !== filterId), // Remove the existing filter with filterId
                        updatedFilter, // Add the updated filter
                    ]
                }

                // Handle reset logic for specific filterId
                if (namesArray.includes('reset all filters')) {
                    // Remove the object with the specified filterId
                    return prev.filter((button) => button.id !== filterId)
                }

                const currentFilterIndex = prev.findIndex(
                    (filter) => filter.id === filterId
                )

                // If the filter exists, update it
                if (currentFilterIndex !== -1) {
                    const newValue = [...prev[currentFilterIndex].value]

                    namesArray.forEach((name) => {
                        const currentIndex = newValue.indexOf(name)
                        if (currentIndex === -1) {
                            newValue.push(name)
                        } else {
                            newValue.splice(currentIndex, 1)
                        }
                    })

                    const updatedFilter = { id: filterId, value: newValue }
                    const updatedButtons = [
                        ...prev.slice(0, currentFilterIndex),
                        updatedFilter,
                        ...prev.slice(currentFilterIndex + 1),
                    ].filter((button) => button.value.length > 0) // Remove empty filters

                    return updatedButtons
                }

                // If the filter does not exist, create a new one
                const newFilter = { id: filterId, value: namesArray }
                return [...prev, newFilter].filter(
                    (button) => button.value.length > 0 // Remove any new filters with empty values
                )
            })
        },
        []
    )

    return { selectedButtons, handleButtonClick, setSelectedButtons }
}

export default useSelectedButtonsLogic
