import { RootState } from '../index'

export const selectResizeWH = (state: RootState) => state.management.resizeWH

export const selectSelectedWardId = (state: RootState) =>
    state.management.selectedWardId

export const selectDoctorNamesList = (state: RootState) =>
    state.management.doctorNamesList

export const selectSelectedDoctor = (state: RootState) =>
    state.management.selectedDoctor

export const selectWardFilters = (state: RootState) =>
    state.management.wardFilters
export const selectedLabsImagingFilters = (state: RootState) =>
    state.management.labsImagingFilters

export const selectFilteredWards = (state: RootState) =>
    state.management.filteredWards
export const selectSelectedPatientId = (state: RootState) =>
    state.management.selectedPatientId
export const selectFilteredLabsRecords = (state: RootState) =>
    state.management.filteredLabsRecords
