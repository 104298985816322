import qs from 'qs'

export const generateLabsAndImagesUrl = (
    baseUrl: string,
    id: string,
    sortDesc: boolean = false,
    page: number = 1,
    pageSize: number = 100000
): string => {
    const queryParams = qs.stringify({
        id,
        sortDesc,
        page,
        pageSize,
    })

    return `${baseUrl}/PatientView/labsandimages?${queryParams}`
}
